import LinkTo from 'components/linkTo';
import { FEATHER_TALLY_BASE } from 'constants/externalLinks';
import { useClient } from 'hooks/useClient';
import { useLocalStorageListener } from 'hooks/useLocalStorageListener';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { ctaLinkResolver } from 'util/ctaLinkResolver';
import { getUrlWithTracker } from 'util/getUrlWithTracker';
import { getInclusiveMembersInLocalStorage } from 'util/liability-insurance';

export const CtaLink = ({
  href,
  children,
  className,
  target = '_self',
  ctaLinkId,
}: {
  href: string;
  children: string;
  className?: string;
  target?: string;
  ctaLinkId?: string;
}) => {
  const [liabilityQuery, setLiabilityQuery] = useState<string>('');

  const hasMounted = useClient();

  const [link, setLink] = useState(href);
  const { locale } = useRouter();

  useLocalStorageListener(() => {
    if (hasMounted) {
      const value = getInclusiveMembersInLocalStorage();
      setLiabilityQuery(value);
    }
  });

  useEffect(() => {
    // Append LP specific params if applicable
    const options = { liability: liabilityQuery }; // ... and more cases to be added in the future
    let newLink = ctaLinkResolver(href, options);

    // Append UTM trackers to Tally form URL
    if (href.startsWith(FEATHER_TALLY_BASE)) {
      newLink = getUrlWithTracker(href);
    }

    setLink(newLink);
  }, [href, liabilityQuery]);

  // internal link
  if (link.startsWith('https://feather-insurance.com')) {
    const internalLink = link.substring('https://feather-insurance.com'.length);
    return (
      <LinkTo
        href={internalLink}
        className={className}
        linkProps={{ locale }}
        target={target}
        id={ctaLinkId}
      >
        {children}
      </LinkTo>
    );
  }

  // external link
  return (
    <a
      href={link}
      className={className}
      rel="noopener noreferrer"
      target={target ?? '_blank'}
      id={ctaLinkId}
    >
      {children}
    </a>
  );
};
